import React, { Fragment } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  ClientsContainer,
  ClientItem,
  ClientLogo,
  NextClient,
  Testimonials,
  TestimonialContainer,
  TestimonialItem,
  TestimonialTitle,
  Pagination,
  PaginateItem,
  NextClientCenter,
  // TheyThink,
  // TheyThinkImg,
  // TheyThinkText,
  // Procucts,
  // Th,
  // Td,
  // Tr,
} from "../styles/clients"
import {
  Section,
  SectionTitle,
  Container,
  Banner,
  SubTitle,
} from "../styles/common"
import Layout from "../components/layout"
import SEO from "../components/seo"
import bankOfAfrika from "../images/clients/bank-of-africa-new.jpg"
import cihBank from "../images/clients/cih-bank.png"
import wafaAssurence from "../images/clients/wafa-assurence.png"
import sonasid from "../images/clients/sonasid.jpg"
import societeGenerale from "../images/clients/societe_generale.png"
import lydec from "../images/clients/lydec.png"
import mediafinance from "../images/clients/mediafinance.jpg"
import agridata from "../images/clients/agridata.png"
import whatever from "../images/clients/whatever.png"
import boultek from "../images/clients/boultek.png"
import tnc from "../images/clients/tnc.jpg"
import cmi from "../images/clients/cmi.png"
import rcar from "../images/clients/rcar.png"
import universiteHassan1 from "../images/clients/uh1.png"
import github from "../images/clients/github.png"
import thenext from "../images/clients/thenext.click.png"
import peaqock from "../images/clients/peaqock4.jpg"
import renault from "../images/clients/renault.png"
import nextClient from "../images/svg/cooperation.svg"
import testimonials from "../static/testimonials"
import SliderContainer from "../components/Slide"

import fb from "../images/suppliers/fb-logo.png"
import ibm from "../images/suppliers/ibm-logo.png"
import oracle from "../images/suppliers/oracle-logo.png"
import redhat from "../images/suppliers/redhat-logo.png"
import m2t from "../images/clients/m2t.png"

// import management from "../images/about/management.jpg"
// import goodCompany from "../images/about/good-company.png"
// import about from "../images/about/about.jpg"
// import achievements from "../static/achievements.json"

const testimonialSettings = {
  dots: true,
  dotsClass: "",
  adaptiveHeight: true,
  customPaging: i => {
    return <PaginateItem key={`paginateItem-${i}`} />
  },
  appendDots: dots => <Pagination>{dots}</Pagination>,
}

const renderTesimonials = () =>
  testimonials.map(testimonial => (
    <TestimonialItem>
      {/* <img
        src={testimonial.profileImg}
        alt={testimonial.name}
      /> */}
      <p>{testimonial.description}</p>
      <h6>
        {testimonial.name}- <div>{testimonial.client}</div>
      </h6>
      <span>{testimonial.role}</span>
    </TestimonialItem>
  ))

const ClientsPage = () => {
  return (
    <Layout>
      <Fragment>
        <SEO
          title="Clients trusted us | x-hub.io"
          keywords={[
            "xhub",
            "devoxx",
            "devoxxmorocco",
            "devoxx4kids",
            "morocco",
            "casablanca",
            "x-hub.io",
            "devc",
            "Application & Technologies",
          ]}
        />
        <Banner clients overlay>
          <Container flex flexCenter>
            <ScrollAnimation
              style={{ width: "100%" }}
              duration={0.4}
              animateOnce={true}
              animateIn="slideInLeft"
            >
              <SectionTitle color="white" transform="uppercase" space="0">
                . Achievements & references
              </SectionTitle>
              <SubTitle color="white">
                Our xTalents are always ready to listen to our customers &
                partners in order to achieve their ambitions and projects
              </SubTitle>
            </ScrollAnimation>
          </Container>
        </Banner>

        <Section bgcolor="#f6f6f6">
          <Container>
            <Testimonials>
              <TestimonialTitle>
                <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                  <SectionTitle
                    align="left"
                    size="55px"
                    color="black"
                    letterSpacing="-1px"
                    lightChild
                  >
                    What they <div>think</div> of us
                  </SectionTitle>
                </ScrollAnimation>
              </TestimonialTitle>

              <TestimonialContainer>
                <SliderContainer settings={testimonialSettings}>
                  {renderTesimonials()}
                </SliderContainer>
              </TestimonialContainer>
            </Testimonials>
          </Container>
        </Section>
        <Section bgcolor="white">
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <SectionTitle
              transform="uppercase"
              space="30px 0 80px"
              lightChild
              color="black"
            >
              OUR <div>References</div>
              <span>
              These companies trusted us to improve their digital projects.
              </span>
            </SectionTitle>
          </ScrollAnimation>
          <ClientsContainer>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={bankOfAfrika} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={cihBank} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={wafaAssurence} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={sonasid} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={renault} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={rcar} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={societeGenerale} />
              </ScrollAnimation>
            </ClientItem>

            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={m2t} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={lydec} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={mediafinance} />
              </ScrollAnimation>
            </ClientItem>

            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={cmi} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={agridata} />
              </ScrollAnimation>
            </ClientItem>

            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={universiteHassan1} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={whatever} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={peaqock} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={tnc} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={boultek} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={thenext} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={github} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <NextClient to="/contact">
                  <img alt="be the next our client" src={nextClient} />
                  Be the next!
                </NextClient>
              </ScrollAnimation>
            </ClientItem>

            {/* <NextClientCenter>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <NextClient to="/contact">
                  <img alt="be the next our client" src={nextClient} />
                  Be the next!
                </NextClient>
              </ScrollAnimation>
            </NextClientCenter> */}
          </ClientsContainer>
        </Section>

        <Section bgcolor="white">
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <SectionTitle
              transform="uppercase"
              space="30px 0 80px"
              lightChild
              color="black"
            >
              <div>Approved Suppliers</div> OF
              {/* <span>
                Those campanies trusted us to improve their digital projects.
              </span> */}
            </SectionTitle>
          </ScrollAnimation>
          <ClientsContainer>
            <ClientItem key={"sup-1"}>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={fb} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem key={"sup-2"}>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={ibm} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem key={"sup-3"}>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={redhat} />
              </ScrollAnimation>
            </ClientItem>
            <ClientItem key={"sup-4"}>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <ClientLogo src={oracle} />
              </ScrollAnimation>
            </ClientItem>
            {/* 
            <ClientItem>
              <ScrollAnimation animateOnce={true} animateIn="zoomIn">
                <NextClient to="/contact">
                  <img alt="be the next our client" src={nextClient} />
                  Be the next!
                </NextClient>
              </ScrollAnimation>
            </ClientItem> */}
          </ClientsContainer>
        </Section>

        {/*<Section bgcolor="white">
        <Container>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <SectionTitle
              space="0 0 80px"
              transform="uppercase"
              color="black"
              lightChild
            >
              What they <div>think of us</div>
            </SectionTitle>
          </ScrollAnimation>
        </Container>
        <TheyThink>
          <TheyThinkImg>
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <img alt="goodCompany" src={goodCompany} />
            </ScrollAnimation>
          </TheyThinkImg>
          <TheyThinkText>
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <h2>Jodi Marshall</h2>
              <h3>Company Owner</h3>
              <p>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </p>
            </ScrollAnimation>
          </TheyThinkText>
        </TheyThink>
        <TheyThink reverse>
          <TheyThinkImg>
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <img alt="management" src={management} />
            </ScrollAnimation>
          </TheyThinkImg>
          <TheyThinkText reverse>
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <h2>Alfred Hodges</h2>
              <h3>Sales Manager</h3>
              <p>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </p>
            </ScrollAnimation>
          </TheyThinkText>
        </TheyThink>
        <TheyThink>
          <TheyThinkImg>
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <img alt="about" src={about} />
            </ScrollAnimation>
          </TheyThinkImg>
          <TheyThinkText>
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <h2>Colleen Gibson</h2>
              <h3>Operations Head</h3>
              <p>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </p>
            </ScrollAnimation>
          </TheyThinkText>
        </TheyThink>
      </Section> */}
        {/* <Section bgcolor="white">
        <ScrollAnimation animateOnce={true} animateIn="slideInUp">
          <SectionTitle
            transform="uppercase"
            color="black"
            lightChild
            space="0 0 80px"
          >
            OUR <div>ACHIEVEMENTS</div>
          </SectionTitle>
        </ScrollAnimation>
        <Container>
          <Procucts>
            <thead>
              <Tr>
                <Th>Year</Th>
                <Th>Client</Th>
                <Th>Role</Th>
              </Tr>
            </thead>
            <tbody>
              {achievements.map((item, i) => (
                <Tr key={`${item.client}-${i}`}>
                  <Td>{item.year}</Td>
                  <Td>{item.client}</Td>
                  <Td>{item.role}</Td>
                </Tr>
              ))}
            </tbody>
          </Procucts>
        </Container>
      </Section> */}
      </Fragment>
    </Layout>
  )
}

export default ClientsPage
