import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { BeTheNext } from "./keyframes"
import quote from "../images/clients/quotes.svg"
import quote2 from "../images/clients/quote-left.svg"

export const ClientsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
export const NextClient = styled(Link)`
  color: black;
  color: blakc;
  font-weight: 900;
  font-size: 25px;
  text-align: center;
  font-family: Lato;
  text-decoration: underline;
  img {
    display: block;
    width: 75%;
    max-width: 100px;
    margin: 10px auto;
    animation: ${BeTheNext} 1.5s infinite;
  }
`
export const ClientItem = styled.div`
  width: 25%;
  display: flex;
  padding: 2% 6%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-top: 2px solid #f5f5f5;
  border-right: 2px solid #f5f5f5;
  transition: 300ms;
  &:nth-child(4n) {
    border-right: 0;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    border-top: 0;
  }

  // &:last-child:hover {
  //   background: rgba(43, 29, 72, 0.1);
  // }
  ${({ theme }) => theme.sm`
    width: 100%;
    border: 0;
    padding: 30px 25%;
    border-bottom: 2px solid #f5f5f5;
  `}
`

export const NextClientCenter = styled(ClientItem)`
  flex: auto;
`

export const ClientLogo = styled.img`
  display: block;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  transition: filter 300ms;

  ${ClientItem}:hover & {
    filter: grayscale(0);
  }

  ${({ theme }) => theme.sm`
    filter: grayscale(0);
   
  `}
`

export const TheyThink = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${props =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `}
`

export const TheyThinkImg = styled.div`
  width: 50%;
  img,
  video {
    display: block;
    width: 100%;
    height: auto;
    outline: none;
  }

  ${({ theme }) => theme.sm`
    width: 100%;
    order: 1;
  `}
`

export const TheyThinkText = styled.div`
  width: 50%;
  background-color: #f6f6f6;
  box-sizing: border-box;
  padding: 5% 7% 2% 5%;
  ${props =>
    props.reverse &&
    css`
      background-color: white;
      padding: 5% 5% 2% 7%;
      text-align: right;
    `}
  h2 {
    position: relative;
    font-size: 25px;
    margin: 0;
    padding-top: 15px;
    font-family: Lato;
    font-weight: 900;
    color: #555;
    text-transform: uppercase;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 20%;
      height: 3px;
      background-color: ${props => props.theme.primaryLightColor};

      ${props =>
        props.reverse &&
        css`
          left: auto;
          right: 0;
        `}
      ${({ theme }) => theme.sm`
        right: auto;
        left: 0;
      `}
    }
  }

  h3 {
    color: #424242;
    margin: 5px 0 25px;
  }

  p {
    color: #424242;
    line-height: 170%;
  }

  ${({ theme }) => theme.sm`
    width: 100%;
    order: 2;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: left;
  `}
`

export const Procucts = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const Th = styled.th`
  color: ${props => props.theme.primaryLightColor};
  font-weight: 600;
  font-size: 18px;
  padding: 20px 10px;
  text-align: left;
  font-family: Lato;

  ${({ theme }) => theme.sm`
      width: 50%;
      display: inline-block;
      box-sizing: border-box;
      &:last-child {
        display: none;
      }
    `}
`

export const Tr = styled.tr`
  border-bottom: 3px solid #f5f5f5;

  ${({ theme }) => theme.sm`
      display: block;
    `}
`

export const Td = styled.td`
  text-align: left;
  padding: 15px 10px;
  font-size: 16px;
  line-height: 150%;
  width: 25%;
  &:last-child {
    width: 50%;
  }

  ${({ theme }) => theme.sm`
      width: 50%;
      display: inline-block;
      box-sizing: border-box;
      padding-bottom: 0;

      &:first-child, &:nth-child(2) {
        color: ${props => props.theme.primaryLightColor};
      }

      &:last-child {
        width: 100%;
        padding-bottom: 30px;
      }
    `}
`
export const Testimonials = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  justify-content: space-between;
`

export const TestimonialTitle = styled.div`
  width: 30%;
  ${({ theme }) => theme.md`
    width: 100%;
  `}
`

export const Pagination = styled.div`
  width: 100%;
  padding: 50px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li {
    margin: 0;
    list-style: none;
  }
`

export const PaginateItem = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  border: 2px solid ${props => props.theme.primaryLightColor};
  border-radius: 50%;

  ${Pagination} .slick-active & {
    background-color: ${({ theme }) => theme.primaryLightColor};
  }
`

export const TestimonialContainer = styled.div`
  width: 60%;
  .slick-list {
    box-shadow: 0 15px 25px rgba(144, 144, 144, 0.4);
  }
  &:after {
    clear: both;
    display: block;
    content: " ";
  }

  ${({ theme }) => theme.md`
    width: 100%;
  `}
`

export const TestimonialItem = styled.div`
  margin-bottom: 0;
  background: white;
  padding: 60px 30px 20px 60px;

  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    width: 35px;
    height: 35px;
    left: 60px;
    top: 15px;
    background: url(${quote2});
    background-size: cover;
  }

  /* &:nth-child(odd) {
    float: right;
    margin-right: -30px;
  }

  &:nth-child(even) {
    float: left;
    margin-left: -30px;
  } */

  p {
    font-size: 15px;
    line-height: 23px;
    margin: 0;
    font-family: Lato;
    text-align: justify;
  }

  h6 {
    font-size: 16px;
    font-weight: 900;
    font-family: Lato;
    margin: 25px 0 5px;
    position: relative;
    padding-left: 20px;
    color: ${props => props.theme.primaryColor};

    div {
      display: inline;
      color: ${props => props.theme.primaryLightColor};
      text-transform: uppercase;
    }
    &:after {
      content: " ";
      position: absolute;
      width: 15px;
      height: 15px;
      left: 0;
      top: -3px;
      background: url(${quote});
      background-size: cover;
    }
  }

  img {
    position: absolute;
    left: -33px;
    top: 48px;
    width: 66px;
    height: auto;
    border-radius: 50%;
    background: #ececec;
  }
  span {
    font-size: 15px;
    line-height: 19px;
    font-family: Lato;
    color: #909090;
    display: block;
    padding-right: 70px;
    padding-left: 20px;

    ${({ theme }) => theme.md`
      padding-right: 0;
    `}
  }

  ${({ theme }) => theme.md`
    margin-left:0 !important;
    margin-right:0 !important;
  `}

  ${({ theme }) => theme.sm`
    width: 100%!important;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  `}
`
