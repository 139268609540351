import testimonial1 from "../images/about/testimonials/testimonial-1.png"
import testimonial2 from "../images/about/testimonials/testimonial-2.png"
import testimonial3 from "../images/about/testimonials/testimonial-3.png"
import testimonial4 from "../images/about/testimonials/testimonial-4.png"

const testimonials = [
  {
    name: "Samira Guerroum",
    description: `Since xHub started working with us, they brought a high
        reliability and a better agile methodology thanks to their
        high expertise level. Their availability, flexibility and
        fundamental qualities of their team to ensure a high-quality
        service make it easy to establish a trusty relation and a
        durable partnership. Clearly xHub is an effective and
        responsive tech expertise partner. He helped us accelerate our
        digital projects and instaure a code culture and innovation
        mindset to our team.`,
    client: "CMI",
    role: "Head of technologies and business development department",
    profileImg: testimonial1,
  },
  {
    name: "M’hammed Skalli",
    description: `We relied on xHub when we had to deal with a very ambitious
        project with very short deadlines. Thanks to their agility,
        flexibility and expertise, xHub teams were able to quickly
        onboard the project, joined forces with our digital department
        and contributed in a decisive way on the success of the
        project. Thumbs up to all the team!!`,
    client: "BOA",
    role: `General Manager Of PASS (Pan African Solutions and Services)
        and Responsible of OSI at Bank of Africa Group`,
    profileImg: testimonial2,
  },
  {
    name: "Mohammed Benkhaled",
    description: `xHub gave a true impulse to our startup through continuous
        support, straight advise and deep expertise. By working with
        them, we grew in maturity, especially with the involvement of
        its CIO and Founder Badr who was completely committed to our
        project given much of his time and passion with no reserve,
        which was paramount in our launching stage`,
    client: "PEAQOCK",
    role: "Founder of Peaqock",
    profileImg: testimonial3,
  },
  {
    name: "Mehdi Zirari",
    description: `When we wanted to launch our digital product, XHUB was a
        natural choice to us. Their expertise, responsiveness and
        commitment were great. XHUB was not just a service provider,
        but a lasting partner who always has our interest at heart.`,
    client: "Mosaic Lab",
    role: "Co-founader of Mosaic Lab",
    profileImg: testimonial4,
  },
]

export default testimonials
